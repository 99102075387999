.sell {
  padding-top:60px ;
  button {
    padding: 0;
    background-color: transparent;
    color: black;
    border: none;
    cursor: pointer;
  }
  .color_buyer td{
    color: rgb(11, 24, 142);
    font-weight: 700;
  }
}
