.profile {
    padding: 50px;
    justify-content: center;
    display: flex;
    gap: 100px;

    div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        img{
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
        button {
            width: 150px;
            height: 25px;
            border: 0;
            margin: auto;
            border-radius: 10px;
            background-color: rgb(0, 148, 155);
            color: white;
        }

        form {
            display: flex;
            flex-direction: column;
            width: 300px;
            gap: 20px;

            input {
                padding: 10px 20px;
                border-radius: 10px;
                border: 0;
            }

            button {
                width: 200px;
                margin: auto;
                height: 30px;
                border: 0;
                border-radius: 10px;
                background-color: rgb(27, 88, 68);
                color: white;
            }
        }
    }
}