// .login{
//     display: flex;
//     justify-content: center;
//     margin-top: 50px;

//     form{
//         display: flex;
//         flex-direction: column;
//         gap: 20px;
//         width: 300px;
//         input{
//             border-radius: 20px;
//             border: 0;
//             padding: 10px 20px;
//         }
//         button{
//             margin: auto;   
//             height: 30px;
//             width: 150px;
//             border: 0;
//             border-radius: 10px;
//             background-color: cadetblue;
//         }
//     }
// }

.login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    // align-items: center;
    padding-top: 150px;
  }
  